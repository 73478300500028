import { lazy } from "react";

const options = [
  {
    key: Math.random(),
    path: "/",
    component: lazy(() => import("../landing/land")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/projects",
    component: lazy(() => import("../projects/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/statistics",
    component: lazy(() => import("../statistics/List")),
    exact: true,
  },

  {
    key: Math.random(),
    path: "/services",
    component: lazy(() => import("../services/List")),
    exact: true,
  },
];
export default options;
